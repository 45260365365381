require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig
if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL' || process.env.VUE_APP_VERSION === 'PRODUCTION') {
  firebaseConfig = {
    apiKey: "AIzaSyDluPauVehv09Z6Wl2aGNzepBkHmS8M3ZU",
    authDomain: "jan-art.firebaseapp.com",
    projectId: "jan-art",
    storageBucket: "jan-art.appspot.com",
    messagingSenderId: "393469967847",
    appId: "1:393469967847:web:71373cd07ab79bd1c09142",
    measurementId: "G-2NM7CELSB4"
  };
} else if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL' || process.env.VUE_APP_VERSION === 'SANDBOX') {
  firebaseConfig = {
    apiKey: "AIzaSyAfgVaSyngfwaujaw8cn9tVEYGEYYXsBrw",
    authDomain: "jan-art-vtest.firebaseapp.com",
    projectId: "jan-art-vtest",
    storageBucket: "jan-art-vtest.appspot.com",
    messagingSenderId: "1007373189802",
    appId: "1:1007373189802:web:0e9780ac53e5e75cbd86d3",
    measurementId: "G-M6LFHKDRN7"
  };
}


  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };