import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Zaloguj się do systemu", title: 'Zaloguj się do systemu' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Login.vue')
  },
  {
    path: '/hub',
    name: 'Hub',
    meta: { pageTitle: "Wybierz moduł - Jan-Art Sp. z o.o.", title: 'Wybierz moduł' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Hub.vue')
  },
  {
    path: '/pos',
    name: 'POS',
    meta: { pageTitle: "POS - Jan-Art Sp. z o.o.", title: 'POS' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/POS.vue')
  },
  {
    path: '/:id',
    name: 'Confirm delivery',
    meta: { pageTitle: "Jan-art Confirm", title: 'Jan-art Confirm' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/DeliveryConfirmation.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'warehouse',
        component: () => import(/* webpackChunkName: "about" */ '../views/StoragePackages.vue'),
        meta: { pageTitle: "Magazyn - Jan-Art Sp. z o.o.", title: 'Magazyn'}
      },
      {
        path: 'prints',
        component: () => import(/* webpackChunkName: "about" */ '../views/PrintCenter.vue'),
        meta: { pageTitle: "Centrum wydruków - Jan-Art Sp. z o.o.", title: 'Centrum wydruków'}
      },
      {
        path: 'drivers',
        component: () => import(/* webpackChunkName: "about" */ '../views/driversHub.vue'),
        meta: { pageTitle: "Zarządzaj kierowcami - Jan-Art Sp. z o.o.", title: 'Zarządzaj kierowcami' }
      },
      {
        path: 'drivers/routes',
        component: () => import(/* webpackChunkName: "about" */ '../views/driversRoutesList.vue'),
        meta: { pageTitle: "Panel kierowcy - Jan-Art Sp. z o.o.", title: 'Panel kierowcy' }
      },
      {
        path: 'drivers/qr',
        component: () => import(/* webpackChunkName: "about" */ '../views/QRcodes.vue'),
        meta: { pageTitle: "Skaner kodów QR - Jan-Art Sp. z o.o.", title: 'Skaner kodów QR' }
      },
      {
        path: 'drivers/maps/:id',
        component: () => import(/* webpackChunkName: "about" */ '../views/DriverMaps.vue'),
        meta: { pageTitle: "Wyznaczona trasa - Jan-Art Sp. z o.o.", title: 'Wyznaczona trasa' }
      },
      {
        path: 'drivers/signature/:id',
        component: () => import(/* webpackChunkName: "about" */ '../views/Signatures.vue'),
        meta: { pageTitle: "Zbieranie popisu - Jan-Art Sp. z o.o.", title: undefined }
      },
      {
        path: 'drivers/photo/:id',
        component: () => import(/* webpackChunkName: "about" */ '../views/PackagePhoto.vue'),
        meta: { pageTitle: "Zdjęcie paczek - Jan-Art Sp. z o.o.", title: 'Zdjęcie paczek' }
      },
      {
        path: 'routes',
        component: () => import(/* webpackChunkName: "about" */ '../views/RoutesHub.vue'),
        meta: { pageTitle: "Menedżer tras - Jan-Art Sp. z o.o.", title: 'Menedżer tras' }
      },
      {
        path: 'routes/create',
        component: () => import(/* webpackChunkName: "about" */ '../views/routeCreate.vue'),
        meta: { pageTitle: "Utwórz nową trasę - Jan-Art Sp. z o.o.", title: 'Utwórz trasę' }
      },
      {
        path: 'routes/list',
        component: () => import(/* webpackChunkName: "about" */ '../views/RoutesList.vue'),
        meta: { pageTitle: "Zarządzaj aktualnymi trasami - Jan-Art Sp. z o.o.", title: 'Lista tras' }
      },
      {
        path: 'routes/print',
        component: () => import(/* webpackChunkName: "about" */ '../views/PrintManager.vue'),
        meta: { pageTitle: "Menadżer wydruków - Jan-Art Sp. z o.o.", title: 'Menadżer wydruków' }
      },
      {
        path: 'routes/archives',
        component: () => import(/* webpackChunkName: "about" */ '../views/RoutesArchives.vue'),
        meta: { pageTitle: "Archwium Tras - Jan-Art Sp. z o.o.", title: 'Archwium tras' }
      },
      {
        path: 'routes/calendar',
        component: () => import(/* webpackChunkName: "about" */ '../views/RoutesCalendar.vue'),
        meta: { pageTitle: "Kalendarz transportowy - Jan-Art Sp. z o.o.", title: 'Kalendarz transportowy' }
      },
      {
        path: 'routes/lookup',
        component: () => import(/* webpackChunkName: "about" */ '../views/routeLookup.vue'),
        meta: { pageTitle: "Podgląd trasy - Jan-Art Sp. z o.o.", title: 'Podgląd trasy' }
      },
      {
        path: 'postcode/list',
        component: () => import(/* webpackChunkName: "about" */ '../views/PostcodeList.vue'),
        meta: { pageTitle: "Lista kodów pocztowych - Jan-Art Sp. z o.o.", title: 'Lista kodów pocztowych' }
      },
      {
        path: 'postcode/add',
        component: () => import(/* webpackChunkName: "about" */ '../views/GeneratePostcode.vue'),
        meta: { pageTitle: "Dodaj grupę kodów pocztowych - Jan-Art Sp. z o.o.", title: 'Dodaj grupę kodów pocztowych' }
      },
      {
        path: 'vehicles/list',
        component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesList.vue'),
        meta: { pageTitle: "Lista pojazdów - Jan-Art Sp. z o.o.", title: 'Lista pojazdów' }
      },
      {
        path: 'vehicles/add',
        component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesAdd.vue'),
        meta: { pageTitle: "Zarządzaj pojazdami - Jan-Art Sp. z o.o.", title: 'Dodaj nowy pojazd transportowy' }
      },
      {
        path: 'manufacturers',
        component: () => import(/* webpackChunkName: "about" */ '../views/ManufacturersHub.vue'),
        meta: { pageTitle: "Zarządzaj producentami - Jan-Art Sp. z o.o.", title: 'Zarządzaj producentami' }
      },
      {
        path: 'storage/events',
        component: () => import(/* webpackChunkName: "about" */ '../views/EventPreview.vue'),
        meta: { pageTitle: "Zarządzaj zdarzeniami, logami - Jan-Art Sp. z o.o.", title: 'Zarządzaj zdarzeniami, logami' }
      },
      {
        path: 'storage',
        component: () => import(/* webpackChunkName: "about" */ '../views/StorageHub.vue'),
        meta: { pageTitle: "Zarządzaj stanem magazynu - Jan-Art Sp. z o.o.", title: 'Zarządzaj stanem magazynu' }
      },
      {
        path: 'storage/orders/list/camera',
        component: () => import('../views/GetOrdersCamera.vue'),
        meta: { pageTitle: "Kompletowanie zamówień - Jan-Art Sp. z o.o.", title: 'Skompletuj zamówienia' }
      },
      {
        path: 'storage/completion',
        component: () => import('../views/CompletionOrders.vue'),
        meta: { pageTitle: "Kompletowanie zamówień - Jan-Art Sp. z o.o.", title: 'Kompletowanie zamówień' }
      },
      {
        path: 'storage/packing',
        component: () => import('../views/PackingOrders.vue'),
        meta: { pageTitle: "Pakowanie zamówień - Jan-Art Sp. z o.o.", title: 'Pakowanie zamówień' }
      },
      {
        path: 'storage/list',
        component: () => import('../views/StorageList.vue'),
        meta: { pageTitle: "Lista produktów w magazynie - Jan-Art Sp. z o.o.", title: 'Lista produktów w magazynie' }
      },
      {
        path: 'storage/process',
        component: () => import('../views/ProcessOrdersList.vue'),
        meta: { pageTitle: "Przetwarzanie zamówień - Jan-Art Sp. z o.o.", title: 'Przetwórz zamówienia' }
      },
      {
        path: 'storage/orders/list',
        component: () => import('../views/StorageManufacturerList.vue'),
        meta: { pageTitle: "Lista zamówień - Jan-Art Sp. z o.o.", title: 'Lista zamówień' }
      },
      {
        path: 'storage/orders/archives',
        component: () => import('../views/ArchiveStorageManufacturerList.vue'),
        meta: { pageTitle: "Archiwum zamówień - Jan-Art Sp. z o.o.", title: 'Archiwum zamówień' }
      },
      {
        path: 'storage/products/order',
        component: () => import('../views/OrderProductsFromList.vue'),
        meta: { pageTitle: "Złóż zamówienie produktu z listy - Jan-Art Sp. z o.o.", title: 'Menu produktów' }
      },
      {
        path: 'storage/scan',
        component: () => import('../views/StorageScan.vue'),
        meta: { pageTitle: "Wydaj paczki na samochód - Jan-Art Sp. z o.o.", title: 'Wydaj paczki na samochód'}
      },
      {
        path: 'manufacturers/list',
        component: () => import('../views/ManufacturersList.vue'),
        meta: { pageTitle: "Lista producentów - Jan-Art Sp. z o.o.", title: 'Lista producentów' }
      },
      {
        path: 'manufacturers/add',
        component: () => import('../views/ManufacturersAdd.vue'),
        meta: { pageTitle: "Dodaj producentów - Jan-Art Sp. z o.o.", title: 'Dodaj producentów do listy' }
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "about" */ '../views/ManageUsers.vue'),
        meta: { pageTitle: "Zarządzaj użytkownikami - Jan-Art Sp. z o.o.", title: 'Zarządzaj użytkownikami systemu' }
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "about" */ '../views/productHub.vue'),
        meta: { pageTitle: "Zarządzaj produktami - Jan-Art Sp. z o.o.", title: 'Zarządzaj produktami' }
      },
      {
        path: 'products/attributes',
        component: () => import(/* webpackChunkName: "Manufacturers" */ '../views/Attributes.vue'),
        meta: { pageTitle: "Atrybuty produktu - Jan-Art Sp. z o.o.", title: 'Atrybuty produktów' }
      },
      {
        path: 'products/attributes/modify',
        component: () => import(/* webpackChunkName: "about" */ '../views/ModfiyAttributes.vue'),
        meta: { pageTitle: "Modyfikuj grupę atrybutów - Jan-Art Sp. z o.o.", title: 'Modyfikuj grupę atrybutów' }
      },
      {
        path: 'products/categories',
        component: () => import(/* webpackChunkName: "Manufacturers" */ '../views/Categories.vue'),
        meta: { pageTitle: "Kategorie produktów - Jan-Art Sp. z o.o.", title: 'Kategorie produktów' }
      },
      {
        path: 'products/storage',
        component: () => import(/* webpackChunkName: "Manufacturers" */ '../views/StorageSegments.vue'),
        meta: { pageTitle: "Zarządzanie magazynami - Jan-Art Sp. z o.o.", title: 'Zarządzanie magazynami' }
      },
      {
        path: 'products/add',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/productAdd.vue'),
        meta: { pageTitle: "Tworzenie produktu - Jan-Art Sp. z o.o.", title: 'Tworzenie produktu' }
      },
      {
        path: 'products/list',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/productsList.vue'),
        meta: { pageTitle: "Lista produktów - Jan-Art Sp. z o.o.", title: 'Lista produktów' }
      },
      {
        path: 'products/list/viewProduct',
        component: () => import(/* webpackChunkName: "Categories" */ '../views/ProductViewmode.vue'),
        meta: { pageTitle: "Zobacz produkt - Jan-Art Sp. z o.o.", title: 'Informacje o produkcie' }
      },
      {
        path: 'products/list/editProduct',
        component: () => import(/* webpackChunkName: "Categories" */ '../views/EditProduct.vue'),
        meta: { pageTitle: "Edytuj produkt - Jan-Art Sp. z o.o.", title: 'Edycja produktu' }
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../views/ShowUserDetails.vue'),
        meta: { pageTitle: "Szczegóły konta - Jan-Art Sp. z o.o.", title: 'Szczegóły konta ' }
      },
      {
        path: 'orders/add',
        component: () => import(/* webpackChunkName: "about" */ '../views/AddOrder.vue'),
        meta: { pageTitle: "Dodaj nowe zamówienie - Jan-Art Sp. z o.o.", title: 'Tworzenie zamówienia' }
      },
      {
        path: 'orders',
        component: () => import(/* webpackChunkName: "about" */ '../views/OrdersHub.vue'),
        meta: { pageTitle: "Menu zamówień - Jan-Art Sp. z o.o.", title: 'Menu zamówień' }
      },
      {
        path: 'orders/list',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/OrdersList.vue'),
        meta: { pageTitle: "Lista zamówień - Jan-Art Sp. z o.o.", title: 'Lista zamówień' }
      },
      {
        path: 'orders/archives',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/OrdersArchiveList.vue'),
        meta: { pageTitle: "Archwium zamówień - Jan-Art Sp. z o.o.", title: 'Archwium zamówień' }
      },
      {
        path: 'awaiting',
        component: () => import(/* webpackChunkName: "about" */ '../views/AwaitingOrdersList.vue'),
        meta: { pageTitle: "Oczekujące zamówienia - Jan-Art Sp. z o.o.", title: 'Oczekujące zamówienia' }
      },
      {
        path: 'scan',
        component: () => import(/* webpackChunkName: "about" */ '../views/ScanAcceptPackage.vue'),
        meta: { pageTitle: "Skan etykiet produktów - Jan-Art Sp. z o.o.", title: 'Zeskanuj zamówienie od producenta' }
      },
      {
        path: 'substract',
        component: () => import(/* webpackChunkName: "about" */ '../views/SubstractStockPackage.vue'),
        meta: { pageTitle: "Skan etykiet produktów - Jan-Art Sp. z o.o.", title: 'Wydaj produkt z magazynu' }
      },
      {
        path: 'relocation',
        component: () => import(/* webpackChunkName: "about" */ '../views/relocationPackage.vue'),
        meta: { pageTitle: "Skan etykiet produktów - Jan-Art Sp. z o.o.", title: 'Zmień lokalizację produktu' }
      },
      {
        path: 'connect',
        component: () => import(/* webpackChunkName: "about" */ '../views/ConnectPackage.vue'),
        meta: { pageTitle: "Skan etykiet produktów - Jan-Art Sp. z o.o.", title: 'Połącz paczki produktu' }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
