<template>
  <div id="app" :class="['w-screen min-h-screen']">
  <Transition name="fade">
  <div v-if="!this.app.active" class="border-l-4 border-blue-500 bg-blue-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm text-blue-700">Dostępna jest nowa aktualizacja oprogramowania. <span class="font-semibold">Naciśnij przycisk, aby odświeżyć stronę.</span></p>
        <p class="mt-3 text-sm md:ml-6 md:mt-0">
          <button @click="refreshProgrammatically()" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-400 transition-all duration-300 flex gap-1">
            Odśwież 
            <RefreshIcon class="w-5 h-5" />
          </button>
        </p>
      </div>
    </div>
  </div>
  </Transition>
    <div :class="[this.app.active ? '' : 'opacity-40 pointer-events-none', 'transition-all duration-300']">
      <router-view />
    </div>
    <Transition name="fade">
      <VersionAlert @closeAlert="closeAlert()" class="absolute left-1/2 transform -translate-x-1/2 top-6 z-50" v-if="versionAlert.show && !versionAlert.deploy" />
    </Transition>
    <Transition name="fade">
      <UpdateAlert @closeAlert="closeNotificationAlert()" :data="app.notification" class="mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 top-10" v-if="app.notification.show" />
    </Transition>
  </div>
  
</template>
<script>
import { auth,db } from "@/firebase/gfbconf";
import UpdateAlert from '@/components/UpdateAlert.vue';
import VersionAlert from '@/components/VersionAlert.vue';
import { XIcon as XMarkIcon } from '@heroicons/vue/solid';
import { RefreshIcon, InformationCircleIcon } from '@heroicons/vue/solid';
export default {
  data() {
    return {
      versionAlert: {
        show: true,
        deploy: true,
      },
      app: {
        version: null,
        active: true,
        notification: {
          show: false,
          head: "",
          subheader: "",
        },
      },
      dbListener: null,
    }
  },
  watch: {
    // Set meta tags
    'app.version'(newVal, oldVal)
    {
      if(oldVal != null)
      {
        if(newVal !== oldVal)
        {
          this.app.active = false;
        }
        else if(newVal === oldVal)
        {
          this.app.active = true;
        }
      }
    },
    $route(to) {
      this.setMeta(to.meta);
    },
  },
  components: {
    VersionAlert, XMarkIcon, RefreshIcon, InformationCircleIcon, UpdateAlert
  },
  methods: {
    refreshProgrammatically()
    {
      this.$router.push('/hub')
      // Clear all cookies
      setTimeout(() => {
        let cookies = document.cookie.split(";");
  
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
  
        // Refresh the page
        location.reload();
      }, 750);
    },
    closeAlert() {
      this.versionAlert.show = false
    },
    closeNotificationAlert()
    {
      this.app.notification.show = false;
    },
    setMeta(meta) {
      document.title = meta.pageTitle;
      if(meta.title === undefined)
      {
        document.title = 'Jan-Art System';
      }
    }
  },
  async created() {
    if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL') {
      this.$store.state.apiLink = "http://127.0.0.1:5001/jan-art-vtest/europe-central2/api"
      this.$store.state.herokuApiLink = "http://127.0.0.1:3000/api"
      this.$store.state.herokuApiPath = "sandbox"
    } else if(process.env.VUE_APP_VERSION === 'SANDBOX') {
      this.$store.state.apiLink = "https://europe-central2-jan-art-vtest.cloudfunctions.net/api"
      this.$store.state.herokuApiLink = "https://janart-app-2d9773139e0a.herokuapp.com/api"
      this.$store.state.herokuApiPath = "sandbox"
    } else if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL') {
      this.$store.state.apiLink = "http://127.0.0.1:5001/jan-art/europe-central2/api"
      this.$store.state.herokuApiLink = "http://127.0.0.1:3000/api"
      this.$store.state.herokuApiPath = "production"
    } else if(process.env.VUE_APP_VERSION === 'PRODUCTION') {
      this.$store.state.apiLink = "https://europe-central2-jan-art.cloudfunctions.net/api"
      this.$store.state.herokuApiLink = "https://janart-app-2d9773139e0a.herokuapp.com/api"
      this.$store.state.herokuApiPath = "production"
    };
    this.dbListener = db.collection('Configs').doc('Listener')
      .onSnapshot((querySnapshot) => {
        this.app.version = querySnapshot.data().version;
        this.app.notification = {
          show: querySnapshot.data().notification.show,
          head: querySnapshot.data().notification.head,
          subheader: querySnapshot.data().notification.subheader,
        };
        this.$store.commit('setAppState', {
          version: this.app.version,
        });
      });
  },
}
</script>
<style>

* {
    -webkit-tap-highlight-color: transparent;
}

body
{
  overflow-x: hidden;
}

.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

</style>

