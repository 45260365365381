
const {db, storage} = require("@/firebase/gfbconf.js");
const axios = require('axios');

module.exports =  {
    triggerOfflineQueue: async function (data){
        console.log("trigger")
        if(data === undefined) return false;
        
        if(!data.done)
        {
            if(data.callClient === "firebase")
            {
                const result = await this.performFirebaseAction(data);
                return result;
            }
            else if(data.callClient === "axios")
            {
                const result = await this.performAxiosAction(data);
                return result;
            }
            else
            {
                return false
            }
        }
    },
    performAxiosAction: async (data) => {
        if(data.callClient !== "axios") return false;
        if(data.call.axios === undefined) return false;
        if(data.call.axios === null) return false;
        if(data.call.axios.config === undefined) return false;
        
        try{
            const result = await axios(data.call.axios.config);
            if(result.status === 200)
            {
                console.log("Axios action done")
                return true;
            }
        }
        catch(error)
        {
            console.log("Error", error);
            return false;
        }

    },
    performFirebaseAction: async (data) => {
        if(data.callClient !== "firebase") return false;
        if(data.call.firebase === undefined) return false;
        if(data.call.firebase === null) return false;
        if(data.call.firebase.payload === undefined) return false;

        const call = data.call;

        if(call.firebase.action === "update")
        {
            
            try{
                const collection = call.firebase.ref.collection;
                const doc = call.firebase.ref.doc;
                const payload = call.firebase.payload

                if(typeof doc !== "string") throw "[Firebase/OfflineMode] Doc should be a string"
                if(typeof collection !== "string") throw "[Firebase/OfflineMode] Collection should be a string"
                if(typeof payload !== "object") throw "[Firebase/OfflineMode] Payload should be an object"

                const ref = db.collection(collection).doc(doc);
                await ref.update(payload);
                
                return true;

            }
            catch(error)
            {
                console.log(error);
                return false;
            }
            
        }
        else if(call.firebase.action === "set")
        {
            try{
                const collection = call.firebase.ref.collection;
                const doc = call.firebase.ref.doc;
                const payload = call.firebase.payload

                if(typeof doc !== "string") throw "[Firebase/OfflineMode] Doc should be a string"
                if(typeof collection !== "string") throw "[Firebase/OfflineMode] Collection should be a string"
                if(typeof payload !== "object") throw "[Firebase/OfflineMode] Payload should be an object"
                
                if(data.originalFunction.name === "updateHistory")
                {
                    await db.collection(collection).doc(doc).collection("History").doc().set(payload);
                    return true;
                }
                else if(data.originalFunction.name === 'saveSignature')
                {
                    await db.collection(collection).doc().set(payload);
                    return true;
                }
                else
                {
                    // Needs to be checked
                    await db.collection(collection).doc(doc).set(payload);
                    return true
                }
            }
            catch(error)
            {
                console.log(error);
            }

        }
        else
        {
            return false;
        }
        



    }
}