<template>
<div  @click="emitClose()" class="select-none flex justify-center">
  <div class="cursor-pointer rounded-md bg-yellow-50 p-4 shadow-sm">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800 flex justify-between">{{data.head}}     
          <button @click.prevent="emitClose()" class="rounded-md inline-flex text-yellow-700 hover:text-yellow-900 duration-300">
            <span class="sr-only">Close</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </h3>
        <div class="mt-2 text-sm text-yellow-700 pr-5 mr-3">
          <p>{{data.subheader}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ExclamationIcon, XIcon } from '@heroicons/vue/solid'

export default {
  props: ['data'],
  components: {
    ExclamationIcon, XIcon
  },
  data() {
    return {
    }
  },
  methods: {
    emitClose()
    {
      this.$emit("closeAlert");
    },
  },
}
</script>

<style>

</style>