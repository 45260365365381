import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import moment from 'moment/moment';
import axios from 'axios';
import VuexPersistence from 'vuex-persist';
import offlineQueues from '@/offlineDriverMode/OfflineQueue.js'
import localforage from 'localforage';

const vuexLocal = new VuexPersistence({
  key: "DriverStorage",
  supportCircular: true,
  storage: localforage,
  reducer: (state) => ({offlineModeState: state.offlineModeState}),
})

// Clear cricular structure error of vuex-persist
// window.localStorage.clear()

//changed window.localStorage to localforage to avoid circular structure error;

export default createStore({
  state: {
    apiLink: "https://europe-central2-jan-art.cloudfunctions.net/api",
    herokuApiLink: "https://janart-app-2d9773139e0a.herokuapp.com/api",
    herokuApiPath: "production",
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    // global state of driver offline mode
    isDriverOnline: true,
    sendingCallsFromQueue: false,
    //  OFFLINE MODE DATA FOR DRIVER (PERSISTED STATE)
    offlineModeState: {
      lastSaveUnix: null,
      backupStatuses: [],
      dataSnapshot: {
        totals: {
          all: null,
          loaded: null,
        },
        route: null,
        orders: null,
      },
      callsQueue: [],
    },
    callForPhotos: {
      order: null,
      taken: false,
    },
    callForScan: {
      order: null,
      taken: false,
    },
    callForSignature: {
      order: null,
      taken: false,
    },
    orderIndex: null,
    productOrdersCart: {},
    variantsCart: {},
    app: {
      active: true,
      version: '',
    },
    user: null,
    route: null,
    userData: null,
    isDriverOnline: false,
    authIsReady: false,
    globalLoader: {
      show: false,
      head: "Loading..",
      subheader: "Don't close this tab."
    }
  },
  plugins: [vuexLocal.plugin],
  mutations: {
    clearDoneOfflineQueue(state)
    {
      if(state.offlineModeState.callsQueue.length === 0) return;
      for(let i=state.offlineModeState.callsQueue.length-1;i>=0;i--)
      {
        let current = state.offlineModeState.callsQueue[i];
        if(current.done === true)
        {
          state.offlineModeState.callsQueue.splice(i,1);
        }
      }
    },
    clearOfflineQueue(state)
    {
      state.offlineModeState.callsQueue = [];
    },
    async performOfflineQueue(state)
    {
      if(state.sendingCallsFromQueue) return;
      if(state.isDriverOnline === true && state.offlineModeState.callsQueue.length > 0)
      {
        state.sendingCallsFromQueue = true;
        for(let i=0;i<state.offlineModeState.callsQueue.length;i++)
        {
          let current = state.offlineModeState.callsQueue[i];
          if(current.done === false)
          {
            const result = await offlineQueues.triggerOfflineQueue(current);
            if(result === true)
            {
              current.done = true;
            }
            else
            {
              current.done = false;
            }
          }
        }
        state.sendingCallsFromQueue = false;
      }
      this.commit("clearDoneOfflineQueue");
    },
    addToOfflineCallsQueue(state, data)
    {
      if(data === undefined) return;

      let call = {
        unixTimestamp: moment().unix(),
        ...data
      };
      state.offlineModeState.callsQueue.push(call)
    },
    setRouteStatusesToDriverOfflineBackup(state,statuses)
    {
      state.offlineModeState.backupStatuses = [...statuses];
    },
    setIsDriverOnline(state,status)
    {
      state.isDriverOnline = status;
      if(status === true && state.offlineModeState.callsQueue.length > 0)
      {
        this.commit("performOfflineQueue");
      }
    },
    // CLEAR PERSIST STATE FOR DRIVER
    clearPersistStateDriver(state)
    {
      console.log("Stan offline wyczyszczony")
      localforage.removeItem('driverStorage');
      localforage.clear();
      state.offlineModeState.lastSaveUnix = null;
      state.offlineModeState.callsQueue = []
      state.offlineModeState.dataSnapshot = {
        totals: {
          all: null,
          loaded: null,
        },
        route: null,
        orders: null,        
      }
    },
    // CREATE OFFLINE SNAPSHOT FOR DRIVERS
    offlineModeSnapshotDriver(state, data)
    {
      
      state.offlineModeState.lastSaveUnix = moment().unix();
      state.offlineModeState.dataSnapshot.route = data.route;
      state.offlineModeState.dataSnapshot.orders = data.orders;
      state.offlineModeState.dataSnapshot.totals.all = data.totalOrdersAmount;
      state.offlineModeState.dataSnapshot.totals.loaded = data.loadedOrdersAmount;
      
      console.log("[OfflineMode/Driver] Snapshot done")
    },
    updateProductOrders(state, productOrdersCart) {
      state.productOrdersCart = productOrdersCart
    },
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    // Set user to Vuex store
    setUser(state, userData)
    {
      state.user = userData;
    },
    setOrderIndex(state, payload)
    {
      state.orderIndex = payload;
    },
    updateInternetConnectionStatus(state, status)
    {
      state.internetConnection = status;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
    },
    setAppState(state, about)
    {
      state.app = {
        active: about.active,
        version: about.version,
      };
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    setRoute(state,payload)
    {
      state.route = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Ładowanie..",
        subheader: "Nie zamykaj tego okna."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },
    addToCart(state, payload)
    {
      for(let i=0; i<payload.length; i++)
      {
        let isFound = false;
        for(let y=0; y<Object.keys(state.variantsCart).length; y++)
        {
          const products = Object.entries(state.variantsCart)
          if(products[y][0] === payload[i].id)
          {
            isFound = true;
            state.variantsCart[payload[i].id].printAmount++;
          }
        }
        if(!isFound)
        {
          state.variantsCart[payload[i].id] = payload[i]
        }
      }
    },
  },
  actions: {
    addVariantsToCart(context, payload)
    {
      context.commit("addToCart", payload);
      context.commit('setNotification',{
        show: true,
        head: "Akcja zakończona sukcesem!",
        subheader: 'Warianty zostały pomyślnie dodane do listy.',
        success: true
     });
    },
    //login new user
    async login(context,payload)
    {
      const signIn = await auth.signInWithEmailAndPassword(payload.email, payload.password);
      if(signIn) {
        context.commit('setGlobalLoader',{
          show: true,
          head: "Sprawdzanie twoich danych..",
          subheader: `Nie zamykaj okna.`,
          success: false
        });
        const userCredentials = await db.collection("UsersData").doc(signIn.user.uid).get();
        if(!userCredentials.exists) throw new Error("Could not complete login action.");
        context.commit('setUserCredentials', userCredentials.data());
        context.commit('setUser', signIn.user);
      }
      else
      {
        throw new Error("Could not complete login action.")
      };
    },
    // logout user;
    async logout(context)
    {
      context.state.authIsReady = false;
      await auth.signOut();
      context.commit('setUser', null);
      setTimeout(() => {
        context.commit("resetGlobalLoader");
      }, 1000);
    },
    // update order history;
    async updateHistory(context, payload) {
      try {
        const {collectionName, docId, message, details} = payload
        const routeId = payload.routeId || null

        await db.collection(collectionName).doc(docId).collection("History").doc().set({
          message,
          details,
          routeId,
          meta: {
            createdDate: moment().toDate(),
            lastModificationDate: moment().toDate(),
            createdBy: `${context.state.userData.name} ${context.state.userData.surname}`,
            userId: context.state.userData.id,
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
  }
})
